import axios from "axios";
import apiClient from "../utils/apiClient";

export const getAllFlows = async () => {
  try {
    const response = await apiClient().get(`call_flows/`);

    return response.data;
  } catch (error) {
    console.error("Error while getting all flows data", error);
    //return error
  }
};

export const getFlowsByWorkspaceId = async (workspace_id) => {
  try {
    const response = await apiClient().get(
      `call_flows/get_all_by_workspace_id/${workspace_id}/`
    );
    return response.data;
  } catch (error) {
    console.error("Error while getting all flows by workspace_id", error);
    //return error
  }
};

export const getFlowsByCompanyId = async (company_id) => {
  try {
    const response = await apiClient().get(
      `call_flows/get_all_by_company_id/${company_id}/`
    );
    return response.data;
  } catch (error) {
    console.error("Error while getting all flows by workspace_id", error);
    //return error
  }
};

export const getDemoFlowsByGroupLink = async (link) => {
  try {
    const response = await apiClient().get(
      `call_flows/get_demo_flows_by_group_link/${link}/`
    );
    return response.data;
  } catch (error) {
    console.error("Error while getting all demo flows by link_id", error);
    //return error
  }
};

export const makeCallProd = async (id, data) => {
  try {
    const response = await apiClient().post(
      `call_flows/make_call/${id}/`,
      data
    );

    return response;
  } catch (error) {
    console.error("Error while make call", error);
    throw error;
    //return error
  }
};
